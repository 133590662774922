/* TabBar.css */

/* Reduce the height of the navbar */
.navbar {
    padding: 5px 16px; /* Adjust the padding to reduce the height */
    border-bottom: 1px solid rgb(216, 216, 216); /* Add a grey border */
    background-color: #ffffff; /* Add a grey background color */
  }
  
  .lan-container { 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  /* Reduce the height of the logo */
  .logo {
    width: auto; /* Adjust the width of the logo */
    height: 30px; /* Maintain aspect ratio */
  }
  
  /* Adjust button padding or font size if needed */
  .btn-primary {
    padding: 6px 12px; /* Adjust button padding */
    font-size: 14px; /* Adjust button font size */
  }
  /* TabBar.css */
  .slogan-container p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 768px) {
    .slogan-container {
      display: none;
    }
  }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to change transparency */
  z-index: 999; /* Ensure the overlay is on top of other content */
}

@media screen and (max-width: 768px) {

  .hide-arrow{ 
    display: none;
  }
}



  .modal-container {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff; /* Change to the desired background color for the modal content */
    transition: opacity 0.3s ease-in-out; /* Define the transition property */

  }

  .modal-container.active {
    display: block;
    opacity: 1;
  }
  

  .modal-content {
    background-color: white; /* Keep the background color for the modal content */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
  }
  
  .scroll-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 20px);
    z-index: 2;
    pointer-events: none; /* Allow clicks to pass through */
  }
  
  .scroll-arrows button {
    font-size: 20px;
    background: transparent;
    border: 1px solid #ccc;
    cursor: pointer;
    outline: none;
    pointer-events: all;
    border-radius: 15px;
    
  }
  
  .scroll-arrows button.left {
    left: 10px;
  }
  
  .scroll-arrows button.right {
    right: 10px;
  }
  
  .date-row {
    position: relative;
    overflow: hidden;
    padding-top: 30px; /* Adjust the top padding for space of arrows */
    transition: 1s ease-in-out;
  }


  
  .scroll-view {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  }
  
  .scroll-view::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome/Safari/Opera */
  }
  
  .date-box {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 15px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px; /* Set a minimum width for each date box */
    text-align: center;
    position: relative;
    border-radius: 15px;
    max-height: 100px;
    width: 150px;
  }
  
  .day {
    /* Styling for the weekday */
    font-weight: bold;
    width: 60px;
  }
  
  .date {
    /* Styling for the date */
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .scroll-view::before,
  .scroll-view::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px; /* Set the width of the scroll viewer */
    z-index: 1;
  }
  
  .scroll-view::before {
    left: 0;
    background: linear-gradient(to right, transparent, white); /* Gradient to create fade effect */
  }
  
  .scroll-view::after {
    right: 0;
    background: linear-gradient(to left, transparent, white); /* Gradient to create fade effect */
  }

  .button-41 {
    background-color: initial;
    background-image: linear-gradient(-180deg, #2d6734, #2d6734);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
    height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
  }
  
  .button-41:hover {
    background: #1e7922;
  }

  .chef-registration-page {
    background-image: url('https://www.aafoodservice.com/wp-content/themes/custom-theme/img/slider-v1704.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    color: white;
  }
 
  .chef-registration-page .card {
    background-color: transparent;
    padding: 20px;
    border-radius: 10px;
  }
 
  .chef-registration-page .cards input[type='text'],
  .chef-registration-page .cards input[type='email'] {
    font-size: 18px; /* Increase the font size of input text */
    padding: 12px; /* Increase padding to make input boxes larger */
    border: 1px solid #ccc; /* Add border */
    border-radius: 5px; /* Add border radius */
    width: 100%; /* Set width to 100% */
    margin-bottom: 15px; /* Add space between input boxes */
  }
 
  .chef-registration-page .cards input[type='text']:focus,
  .chef-registration-page .cards input[type='email']:focus {
    outline: none; /* Remove outline on focus */
    border-color: #5b9cc4; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(91, 156, 196, 0.5); /* Add box shadow on focus */
  }
 


 
  .chef-registration-page h1 {
    font-size: 50px; /* Increase the font size of headings */
  }






  .chef-registration-page p {
    font-size: 30px; /* Increase the font size of headings */
  }
