.landing-page { 
  height: auto;
}
.category-item.selected {
  border: 1px solid #2d6734; /* You can customize the border color */
  border-radius: 20px;
  color: #2d6734;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: 5%;
}

.loading-text {
  color: #000000;
}

/* CardContent.css */
.card-custom {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  min-width: 100%;
  max-width: 100%;
  max-height: 310px;
  min-height: 310px;
}
.data-filter-container { 
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}

.faleft { 
  border: 1px solid #2d6734;
  border-radius: 50%;
  color: #2d6734;
  padding: 10px;
  cursor: pointer;
  margin-right: 15px;
}

.faleft:hover { 
  background-color: #2d6734;
  color: #fff;
}

.image-container {
  position: relative;
  width: 100%;
  height: 200px; /* Adjust the height of the image container */
  min-width: 100%;
  max-width: 100%;
  max-height: 200px;
  min-height: 200px;
}
/* Update TabBar.css */
.tab-bar {
  /* Your existing styles */
  /* Make sure to set a height for the TabBar */
  height: 53px; /* Example height; adjust as needed */
}
/* Landing.css */
.card-title,
.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sticky-filter {
  position: -webkit-sticky;
  position: sticky;
  top: 52px; /* Ensure it sticks to the top */
  z-index: 100; /* Ensure it appears above other content */
  background-color: #fff;
  /* Other styles */
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  border: 4px solid white; /* Add a white border with 3px width */
  border-radius: 50%; /* Make the border round */
  overflow: hidden;
  transform: translate(0%, 50%);
}

.card-body { 
  width: 70%;
}

  
  .date-filter-header {
    cursor: pointer;
    font-size: 24px;
    
    /* Your header styles */
  }
  
  .delivery-text {
    font-weight: 400;
}

.delivery-text-bold {
    font-weight: bold; /* Make the text bold */
    text-decoration: underline; /* Underline the text */
  }

  .city-button-container {
    margin-left: 15px;
    
  }

  .category-filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Your container styles */
  }
  
  .arrow-scroll {
    cursor: pointer;
    min-width: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%; /* Make it round */
    width: 50px; /* Set width and height for the circle */
    min-height: 50px;
    margin: 0 10px; /* Adjust spacing */
    transition: background-color 0.3s, transform 0.3s; /* Adding transition */
    border: 2px solid #e2e2e2; /* Add a border */
    color: black;
  }
  
  .arrow-scroll:hover {
    background-color: #2d6734; /* Change background color on hover */
    transform: scale(1.1); /* Increase size on hover */
  }
  
  .category-scroll {
    display: flex;
    overflow-x: hidden; /* Hide the scrollbar */
    scroll-behavior: smooth;
    /* Other scroll container styles */
  }
  
  .category-item {
    /* Your category item styles */
    padding: 0px 8px;
    margin-right: 8px;
    /* Style as needed */
    border-bottom: 1px solid transparent; /* Set a transparent border by default */
    transition: border-color 0.3s; /* Adding transition for smooth effect */
    font-size: 18px;
    font-family: "Gill Sans", sans-serif;  
    color: rgb(73, 72, 72);
    white-space: nowrap;
  }
  
  .category-item:hover {
    color: #1e8449; /* Change color on hover */
    border-bottom-color: #ccc; /* Change border color to gray on hover */
    cursor: pointer;
  }

  @media (min-width: 768px) {
    .category-scroll {
      overflow-x: none;
      scrollbar-width: thin;
      scrollbar-color: grey transparent; /* Customize scrollbar colors */
      white-space: nowrap; /* Keep items in a row */
    }
    
    .arrow-scroll {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%; /* Make it round */
        width: 50px; /* Set width and height for the circle */
        height: 25px;
        transition: background-color 0.3s, transform 0.3s; /* Adding transition */
        border: 1px solid #ccc; /* Add a border */
      }
  }
  
  /* Hide scroller and allow content to be scrollable on smaller screens */
@media (max-width: 767px) {
  .category-scroll {
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  }
  
  .arrow-scroll {
    display: none; /* Hide the scroll arrows on smaller screens */
    
  }
}
.category-scroll {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
    -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
  
  .category-scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, Opera */
  }
  
  .scrolling-wrapper {
    white-space: nowrap;
  }








.modal-content {
  width: 100%;
  max-width: 100%;
}































.button-1 {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
}

.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-6 {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.main-title{ 
  font-family: Gilroy, Helvetica, Arial, "sans-serif";
  font-weight: 700;
  line-height: 120%;
  color: rgb(56, 56, 56);
  font-size: 22px;
  text-decoration: underline;
  cursor: pointer;
}


.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
