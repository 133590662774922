.vendor_cover_image { 
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px;
    object-position: 50%;
    
}

@media (max-width: 768px) {
    .position-relative {
      position: relative;
    }
    .position-absolute {     
        float: right;
        position: absolute;
        right: 10px;

        border: 3px solid #ffffff;
        border-radius: 50%;
    }
    .content-margin { 
        margin-top: 45px;
    }

    .scroll-button { 
        display: none;
    }
  }

.text-title{ 
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.margin-avatar { 
  border: 1px solid #2d6734;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.text-content { 
    font-size: 20px;
    color: #555;
    font-family: Gilroy, Helvetica, Arial, "sans-serif";
    font-weight: 500;
}

.text-description { 
    font-size: 19px;
    color: rgb(92, 92, 92);
    margin-bottom: 10px;
    font-family: Gilroy, Helvetica, Arial, "sans-serif";
    word-wrap: break-word;
}

.text-fee { 
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
    font-family: Gilroy,Helvetica,Arial,"sans-serif";
    font-weight: 600;
}

.margain-avatar { 
    margin: 0
}

  .back-button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px; /* Adjust as needed */
  }

.menu-image { 
    object-fit: cover;
    object-position: center center;
    max-height: 242px;
    width: 100%;
    height: 242px;
    min-height: 242px;
}

.card { 
    border-radius: 15px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Add transition for smooth effect */
    height: 100%;
    cursor: pointer;
}

.card:hover { 
    transform: scale(1.01); /* Change the scale on hover */
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.bottom-half-content { 
    background-color: white;
    height: auto;
    min-height: 100vh;
}

.order-button-fullwidth {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
    z-index: 100;
  }


.date-row-main { 
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.scroll-button { 
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 10px;
    transition: all 0.3s ease; /* Add transition for smooth effect */
  }

.scroll-button:hover { 
    background-color: #f7f4f4;
    transform: scale(1.1); /* Change the scale on hover */
}

.scroll-button:active { 
    background-color: #f7f4f4;
    transform: scale(1.1); /* Change the scale on hover */
}

.date-box{ 
    transition: all 0.3s ease; /* Add transition for smooth effect */
}

.date-box:hover { 
    transform: scale(1.1); /* Change the scale on hover */
}

.date-box.today{ 
    background-color: #dbd8d8;
}

.date-box.selected { 
    background-color: #dbd8d8;
}



.button-68 {
  appearance: none;
  backface-visibility: hidden;
  background-color: #2d6734;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-68:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.button-68:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.button-68:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}
.button-48 {
  appearance: none;
  background-color: #FFFFFF;
  border-width: 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-block;
  font-family: Clarkson,Helvetica,sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 1.5em 2.2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1),background-color 100ms cubic-bezier(.694, 0, 0.335, 1),color 100ms cubic-bezier(.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-48:before {
  animation: opacityFallbackOut .5s step-end forwards;
  backface-visibility: hidden;
  background-color: #556cf1;
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
  width: 100%;
}

.button-48:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.button-48:after {
  background-color: rgb(167, 6, 6);
}

.button-48 span {
  z-index: 1;
  position: relative;
}



.button-58 {
  align-items: center;
  background-color: #2d6734;
  border: 2px solid #2d6734;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter,sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-58:focus {
  color: #171e29;
}


.button-58:hover {
  background-color: #0f8c4f;
  border-color: #0f8c4f;
  color: #fff;
  fill: #fff;
  scale: 1.1;
}

.button-58:active {
  background-color: #0f8c4f;
  border-color: #0f8c4f;
  color: #fff;
  fill: #fff;
}

@media (min-width: 768px) {
  .button-58 {
    min-width: 170px;
  }
}
