
  .material-icons{
    font-size: 50px;
  }
  .interactive-faq {
    max-width: 800px;
    margin: auto;
  }
  
  .faq-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .faq-item {
    border: 1px solid #ccc;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    transition: border-color 0.3s ease-in-out;
  }
  
  .faq-item:hover {
    border-color: #007bff;
  }
  
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 15px;
    cursor: pointer;
  }
  
  .question-text {
    flex-grow: 1;
    font-weight: bold;
  }
  
  .answer {
    padding: 15px;
    background-color: #ffffff;
    transition: max-height 0.3s ease-in-out;
  }
  
  .expanded .answer {
    height: auto; /* Adjust as needed */
  }
  
  .answer p {
    margin: 0;
  }
  
  .question svg {
    font-size: 1.2rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .expanded .question svg {
    transform: rotate(180deg);
  }
  
  .content-container {
    background-color: white;
    padding: 38px;
    box-shadow: rgba(0, 0, 0, 0.04) 1px 8px 15px; /* Optional: Add a shadow effect */
    max-width: 576px;
    border: 1px solid rgb(235, 235, 236);
    border-radius: 10px;
    word-wrap: break-word;
}

.title-text { 
    font-size: 48px;
    font-weight: bold;
}
.description-home{ 
}

.content-text { 
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 768px) {
  .home-container { 
    height: '30vh';
  }
}

.input-container {
  background-color: #ffffff; /* Your desired background color */
  padding: 15px; /* Adjust padding as needed */
  border-radius: 5px; /* Adjust border-radius as needed */
  border-top: 1px solid #dcdcdc; /* Add a grey border on top */
}

.enter-input-container{
  display: flex;
}

.custom-home-card .description-home {
  height: 100px; /* Adjust the height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Adjust the number of lines to display */
  -webkit-box-orient: vertical;
}


/* exlpore.css */
.additional-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh; /* Set the fixed height for the tab bar */
    background-color: #f8f9fa; /* Background color for the tab bar */
    gap: 40px; /* Adjust gap between items */
    background-color: aquamarine;
  }
  
  .additional-content div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px; /* Adjust margin between items */
  }
  
  .additional-content div span {
    font-size: 12px;
    font-weight: bold;
  }

  .input-group {
    margin-top: 20px; /* Margin between content and input group */
  }

  .fa-solid {
    font-size: 24px; /* Adjust icon size */
    margin-right: 10px; /* Margin between icon and text */
  }

  .explore-section {
    padding: 40px 20px;
    background-color: white;
    text-align: center;
  }
  
  /* Styles for the search icon */
  .search-icon {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
    font-family:Georgia, 'Times New Roman', Times, serif;
  }
  
  /* Styles for the explore content */
  .explore-content h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .explore-content p {
    font-size: 1.1rem;
    color: #555;
}

/* Styles for the tags */
.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Horizontally center items */
    margin-top: 20px;
    max-width: 700px;
    margin-left: auto; /* Align the container to the center */
    margin-right: auto; /* Align the container to the center */
}
  
  .tag {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tag:hover {
    background-color: #0056b3;
  }

  /* stlyes for safety section */
  .safe-content {
    display: flex;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;;
  }

  .safe-img {
    width: 50%;
  }
  
  .safe-img img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 15px;
  }
  
  .safe-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px; /* Add padding for better spacing */
  }
  
  .safe-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .safe-subtitle {
    font-size: 1rem;
    color: #555;
  }

  @media (max-width: 768px) {
    .safe-content {
      flex-direction: column;
    }
  
    .safe-img,
    .safe-text {
      width: 100%;
    }
  
    .safe-img {
      margin-bottom: 20px;
    }
  }

    /* styles for qa */
    .accordion-button {
        width: 100%;
        text-align: left;
        padding: 1rem;
        background-color: #f8f9fa;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
   
/* YourComponent.css */
.join-container {
    background-image: url('https://i.ibb.co/3fCvVRZ/end-shefs-grid-image.webp');
    background-repeat: no-repeat;
    background-position: center center;
    height: 75vh; /* Set the height relative to 80% of the viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px; /* Add padding as needed */

  }

  @media (max-width: 576px) {
    .arrow {
      display: none; /* Hide arrows on smaller screens */
    }
  }

  .avatar-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Horizontally center items */
    margin-top: 20px;
    max-width: 700px;
    margin-left: auto; /* Align the container to the center */
    margin-right: auto; /* Align the container to the center */
  }

  .custom-home-card { 
    max-width: 700px;
    width: 700px;
  }
  
  /* Style for the chef avatars */
  .chef-avatar {
    margin: 10px; /* Add margin around each avatar */
    transition: all 0.3s ease; /* Add transition for smooth effect */

  }

  .chef-avatar:hover { 
    transform: scale(1.1); /* Change the scale on hover */
    cursor: pointer;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 768px) {
    .chef-avatar {

    }
  }

  /* Style for Explore More Chefs box */
.explore-more {
  width: 700px;
  max-width: 700px;
  height: 100px;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 40px;
  margin: 20px;
  transition: all 0.3s ease; /* Add transition for smooth effect */
  color: #2d6734;
}

.explore-more:hover {
  transform: scale(1.1); /* Change the scale on hover */
}
/* Style for text inside the Explore More Chefs box */
.explore-more p {
  margin: 0;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .custom-margin-av { 
    margin: 5px;
  }
  
}


.button-37 {
  background-color: #2d6734;
  border: 1px solid #2d6734;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-37:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-37 {
    padding: 10px 30px;
  }
}
 
  .button-79 {
    backface-visibility: hidden;
    background: #332cf2;
    border: 0;
    border-radius: .375rem;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Circular,Helvetica,sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: -.01em;
    line-height: 1.3;
    padding: 1rem 1.25rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-79:disabled {
    color: #787878;
    cursor: auto;
  }
  
  .button-79:not(:disabled):hover {
    transform: scale(1.05);
  }
  
  .button-79:not(:disabled):hover:active {
    transform: scale(1.05) translateY(.125rem);
  }
  
  .button-79:focus {
    outline: 0 solid transparent;
  }
  
  .button-79:focus:before {
    border-width: .125rem;
    content: "";
    left: calc(-1*.375rem);
    pointer-events: none;
    position: absolute;
    top: calc(-1*.375rem);
    transition: border-radius;
    user-select: none;
  }
  
  .button-79:focus:not(:focus-visible) {
    outline: 0 solid transparent;
  }
  
  .button-79:not(:disabled):active {
    transform: translateY(.125rem);
  }



.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
