/* LanguageToggle.css */

.language-toggle {
    background-color: #2d6734;  /* Green background color */
    color: #fff;  /* White text color */
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .language-icon {
    margin-right: 8px;  /* Adjust the spacing between the icon and text */
  }
  
  .language-text {
    font-weight: bold;
  }
  
  .language-toggle:hover {
    background-color: #45a049;  /* Darker green for hover effect */
  }
  