.footer-logo {
    width: 180px;
    float: left;
}

@media screen and (max-width: 767px) {
    .footer-logo {
        width: 180px;
        float: none;
        text-align: center;
    }
    
}

